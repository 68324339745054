$spaceamounts: (
  2,
  3,
  4,
  5,
  6,
  8,
  10,
  12,
  13,
  15,
  18,
  20,
  22,
  25,
  30,
  32,
  34,
  35,
  36,
  38,
  40,
  44,
  45,
  46,
  50,
  55,
  60,
  65,
  75,
  80,
  100
); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}
